// ErrorBroken.js
import React from 'react';
import './ComingSoon.css'; // Importing CSS styles


const ErrorBroken = () => {
    return (
        <div className="ErrorBroken">
            
            <div className="overlay-broken">
            <img src={require('./robot-brokenpipe.png')} className="img-broken" />
                <h1>Opsss ....</h1>
                    <p>Eu não fiz nada</p>
            </div>
        </div>
        
    );
};

export default ErrorBroken;