import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import ComingSoon from './ComingSoon'; // Import the ComingSoon component
import ErrorPage500 from './ErrorPage500';
import ErrorBroken from './ErrorBroken';


const App = () => {
  return (
    <>
    <Suspense fallback={<div className="container">Loading...</div>}></Suspense>
    <Routes>
      <Route path="/" element={<ComingSoon />} />
      <Route path="/tilt" element={<ErrorPage500 />} />
      <Route path="/errorpage" element={<ErrorBroken />} />
    </Routes>
    </>
  );
}

export default App;
