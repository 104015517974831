// ErrorPage500.js
import React from 'react';
import './ComingSoon.css'; // Importing CSS styles


const ErrorPage500 = () => {
    return (
        <div className="Error_500">
            <div className="overlay-500">
                <h1>Tilt! Parece que nossa IA se perdeu...</h1>
                    <p>Tente novamente mais tarde</p>
            </div>
        </div>
        
    );
};

export default ErrorPage500;